import React from 'react';
import { Link } from 'react-router-dom';
import TopDownloads from './TopDownloads';
import EditorsChoice from './EditorsChoice';
import './Home.css'; 
import { articles } from '../data/articles/articles';
import { Helmet } from 'react-helmet';
import SearchHero from '../components/SearchHero';

// const categories = [
//   { 
//     id: 'social', 
//     name: 'Social', 
//     icon: '👥',
//     path: '/category/social'
//   },
//   { 
//     id: 'entertainment', 
//     name: 'Entertainment', 
//     icon: '🎭',
//     path: '/category/entertainment'
//   },
//   { 
//     id: 'finance', 
//     name: 'Finance', 
//     icon: '💰',
//     path: '/category/finance'
//   },
//   { id: 'communication', name: 'Communication', icon: '💬' },
//   { id: 'video-players', name: 'Video Players & Editors', icon: '🎥' },
//   { id: 'personalization', name: 'Personalization', icon: '🎨' },
//   { id: 'maps', name: 'Maps & Navigation', icon: '🗺️' },
//   { id: 'business', name: 'Business', icon: '💼' },
//   { id: 'travel', name: 'Travel & Local', icon: '✈️' },
//   { id: 'productivity', name: 'Productivity', icon: '📊' },
//   { id: 'comics', name: 'Comics', icon: '📚' },
//   { id: 'beauty', name: 'Beauty', icon: '💄' },
//   { id: 'adventure', name: 'Adventure', icon: '🗺️' },
//   { id: 'action', name: 'Action', icon: '🎮' },
//   { id: 'arcade', name: 'Arcade', icon: '🕹️' },
//   { id: 'board', name: 'Board', icon: '🎲' },
//   { id: 'dating', name: 'Dating', icon: '❤️' },
//   { id: 'role-playing', name: 'Role Playing', icon: '🎭' },
//   { id: 'tools', name: 'Tools', icon: '🔧' },
//   { id: 'lifestyle', name: 'Lifestyle', icon: '🌟' },
//   { id: 'weather', name: 'Weather', icon: '🌤️' },
//   { id: 'education', name: 'Education', icon: '📚' },
//   { id: 'art-design', name: 'Art & Design', icon: '🎨' },
//   { id: 'parenting', name: 'Parenting', icon: '👶' },
//   { id: 'house-home', name: 'House & Home', icon: '🏠' },
//   { id: 'libraries-demo', name: 'Libraries & Demo', icon: '📚' },
//   { id: 'puzzle', name: 'Puzzle', icon: '🧩' },
//   { id: 'card', name: 'Card', icon: '🃏' },
//   { id: 'strategy', name: 'Strategy', icon: '♟️' },
//   { id: 'trivia', name: 'Trivia', icon: '❓' },
//   { id: 'sports', name: 'Sports', icon: '⚽' },
//   { id: 'educational', name: 'Educational', icon: '📖' },
//   { id: 'shopping', name: 'Shopping', icon: '🛍️' },
//   { id: 'food-drink', name: 'Food & Drink', icon: '🍽️' },
//   { id: 'photography', name: 'Photography', icon: '📸' },
//   { id: 'music-audio', name: 'Music & Audio', icon: '🎵' },
//   { id: 'news-magazines', name: 'News & Magazines', icon: '📰' },
//   { id: 'health-fitness', name: 'Health & Fitness', icon: '💪' },
//   { id: 'books-reference', name: 'Books & Reference', icon: '📚' },
//   { id: 'medical', name: 'Medical', icon: '⚕️' },
//   { id: 'auto-vehicles', name: 'Auto & Vehicles', icon: '🚗' },
//   { id: 'casual', name: 'Casual', icon: '🎲' },
//   { id: 'simulation', name: 'Simulation', icon: '🎮' },
//   { id: 'word', name: 'Word', icon: '📝' },
//   { id: 'events', name: 'Events', icon: '📅' },
//   { id: 'racing', name: 'Racing', icon: '🏎️' }
// ];


const categories = [
  { id: 'communication', name: 'Communication', icon: '💬', path: '/category/communication' },
  { id: 'video-players', name: 'Video Players & Editors', icon: '🎥', path: '/category/video-players' },
  { id: 'personalization', name: 'Personalization', icon: '🎨', path: '/category/personalization' },
  { id: 'maps', name: 'Maps & Navigation', icon: '🗺️', path: '/category/maps' },
  { id: 'business', name: 'Business', icon: '💼', path: '/category/business' },
  { id: 'travel', name: 'Travel & Local', icon: '✈️', path: '/category/travel' },
  { id: 'productivity', name: 'Productivity', icon: '📊', path: '/category/productivity' },
  { id: 'comics', name: 'Comics', icon: '📚', path: '/category/comics' },
  { id: 'beauty', name: 'Beauty', icon: '💄', path: '/category/beauty' },
  { id: 'adventure', name: 'Adventure', icon: '🗺️', path: '/category/adventure' },
  { id: 'action', name: 'Action', icon: '🎮', path: '/category/action' },
  { id: 'arcade', name: 'Arcade', icon: '🕹️', path: '/category/arcade' },
  { id: 'board', name: 'Board', icon: '🎲', path: '/category/board' },
  { id: 'dating', name: 'Dating', icon: '❤️', path: '/category/dating' },
  { id: 'role-playing', name: 'Role Playing', icon: '🎭', path: '/category/role-playing' },
  { id: 'social', name: 'Social', icon: '👥', path: '/category/social' },
  { id: 'finance', name: 'Finance', icon: '💰', path: '/category/finance' },
  { id: 'tools', name: 'Tools', icon: '🔧', path: '/category/tools' },
  { id: 'lifestyle', name: 'Lifestyle', icon: '🌟', path: '/category/lifestyle' },
  { id: 'weather', name: 'Weather', icon: '🌤️', path: '/category/weather' },
  { id: 'education', name: 'Education', icon: '📚', path: '/category/education' },
  { id: 'art-design', name: 'Art & Design', icon: '🎨', path: '/category/art-design' },
  { id: 'parenting', name: 'Parenting', icon: '👶', path: '/category/parenting' },
  { id: 'house-home', name: 'House & Home', icon: '🏠', path: '/category/house-home' },
  { id: 'libraries-demo', name: 'Libraries & Demo', icon: '📚', path: '/category/libraries-demo' },
  { id: 'puzzle', name: 'Puzzle', icon: '🧩', path: '/category/puzzle' },
  { id: 'card', name: 'Card', icon: '🃏', path: '/category/card' },
  { id: 'strategy', name: 'Strategy', icon: '♟️', path: '/category/strategy' },
  { id: 'trivia', name: 'Trivia', icon: '❓', path: '/category/trivia' },
  { id: 'sports', name: 'Sports', icon: '⚽', path: '/category/sports' },
  { id: 'educational', name: 'Educational', icon: '📖', path: '/category/educational' },
  { id: 'shopping', name: 'Shopping', icon: '🛍️', path: '/category/shopping' },
  { id: 'food-drink', name: 'Food & Drink', icon: '🍽️', path: '/category/food-drink' },
  { id: 'photography', name: 'Photography', icon: '📸', path: '/category/photography' },
  { id: 'music-audio', name: 'Music & Audio', icon: '🎵', path: '/category/music-audio' },
  { id: 'news-magazines', name: 'News & Magazines', icon: '📰', path: '/category/news-magazines' },
  { id: 'health-fitness', name: 'Health & Fitness', icon: '💪', path: '/category/health-fitness' },
  { id: 'books-reference', name: 'Books & Reference', icon: '📚', path: '/category/books-reference' },
  { id: 'medical', name: 'Medical', icon: '⚕️', path: '/category/medical' },
  { id: 'auto-vehicles', name: 'Auto & Vehicles', icon: '🚗', path: '/category/auto-vehicles' },
  { id: 'casual', name: 'Casual', icon: '🎲', path: '/category/casual' },
  { id: 'simulation', name: 'Simulation', icon: '🎮', path: '/category/simulation' },
  { id: 'word', name: 'Word', icon: '📝', path: '/category/word' },
  { id: 'events', name: 'Events', icon: '📅', path: '/category/events' },
  { id: 'racing', name: 'Racing', icon: '🏎️', path: '/category/racing' },
  { id: 'entertainment', name: 'Entertainment', icon: '🎭', path: '/category/entertainment' }
];

function Home() {
  return (
    <div className="home">
      <Helmet>
        <title>AppFinder - Download Apps for Android and iOS</title>
      </Helmet>
      <SearchHero 
        backgroundImage="/Monopoly_1.jpg"
        appIcon="Monopoly_icon.png"
        appName="Monopoly GO!"
        appDescription="Roll the dice and get rich in MONOPOLY GO! "
      />
      <div className="content-container">
        <div className="section-header">
          <h2>Popular Apps</h2>
          <Link to="/top-downloads/index.html" className="view-all">
            More
          </Link>
        </div>
        <div className="apps-list">
          <TopDownloads limit={12} showTitle={false} />
        </div>

        <div className="articles-section">
          <div className="section-header">
            <h2>Latest Articles</h2>
            <Link to="/articles/index.html" className="view-all">
              More
            </Link>
          </div>
          <div className="articles-grid">
            {articles.slice(0, 3).map((article) => (
              <Link
                key={article.id}
                to={`/articles/${article.id}/index.html`}
                className="article-card"
              >
                {article.coverImage && (
                  <div className="article-image">
                    <img src={article.coverImage} alt={article.title} />
                  </div>
                )}
                <div className="article-info">
                  <h3>{article.title}</h3>
                  <time>{article.date}</time>
                </div>
              </Link>
            ))}
          </div>
        </div>

        <div className="section-header">
          <h2>Editors' Choice</h2>
          <Link to="/editors-choice/index.html" className="view-all">
            More
          </Link>
        </div>
        <div className="apps-list">
          <EditorsChoice limit={12} showTitle={false} />
        </div>

        <div className="app-categories">
          <div className="section-header">
            <h2>App Categories</h2>
          </div>
          <div className="categories-grid">
            {categories.map((category) => (
              <Link
                key={category.id}
                to={`/category/${category.id}/index.html`}
                className="category-card"
              >
                <div className="category-icon">{category.icon}</div>
                <div className="category-name">{category.name}</div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;