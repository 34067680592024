import React from 'react';
import privacyData from '../data/terms/privacy.json';
import '../styles/pages/PrivacyPage.css';

function Privacy() {
  const { privacy_policy } = privacyData;

  return (
    <div className="privacy-page">
      <div className="privacy-container">
        <h1>Privacy Policy</h1>

        <section className="privacy-section">
          <h2>Introduction</h2>
          <p>{privacy_policy.introduction.description}</p>
        </section>

        <section className="privacy-section">
          <h2>Information We Collect</h2>
          <h3>Information You Provide</h3>
          <ul>
            <li>{privacy_policy.information_we_collect.data_you_provide_directly.account_registration}</li>
            <li>{privacy_policy.information_we_collect.data_you_provide_directly.interactions}</li>
            <li>{privacy_policy.information_we_collect.data_you_provide_directly.customer_support}</li>
          </ul>

          <h3>Automatically Collected Information</h3>
          <ul>
            <li>{privacy_policy.information_we_collect.data_collected_automatically.device_and_browser_information}</li>
            <li>{privacy_policy.information_we_collect.data_collected_automatically.usage_data}</li>
            <li>{privacy_policy.information_we_collect.data_collected_automatically.cookies_and_tracking_technologies}</li>
          </ul>
        </section>

        <section className="privacy-section">
          <h2>How We Use Your Information</h2>
          <ul>
            {privacy_policy.how_we_use_your_information.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </section>
      </div>
    </div>
  );
}

export default Privacy; 