import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import appService from '../services/appService';
import './AppDetail.css';

function AppDetail() {
  const { id } = useParams();
  const [app, setApp] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAppDetails = async () => {
      try {
        console.log('Fetching app with ID:', id);
        const appData = await appService.getAppById(id);
        console.log('Raw app data:', appData);
        console.log('Similar apps:', appData?.similar_apps);
        
        setApp(appData);
      } catch (error) {
        console.error('Error fetching app details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAppDetails();
  }, [id]);

  // 在渲染之前也添加一个调试日志
  if (app) {
    console.log('Current app state:', app);
    console.log('Similar apps in state:', app.similar_apps);
  }

  const formatDescription = (description) => {
    if (!description) return 'No description available';
    
    // 按句号分割成独立段落，并清理每个段落
    return description
      .split('.')  // 按句号分割
      .map(sentence => sentence.trim())  // 清理每个句子
      .filter(sentence => sentence.length > 0)  // 移除空句子
      .join('.\n\n');  // 每个句子后加句号和换行
  };

  // 显示加载状态
  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  // 显示错误状态
  if (!app) {
    return (
      <div className="app-not-found">
        <h2>App not found</h2>
        <Link to="/" href="/" className="back-link">Back to Home</Link>
      </div>
    );
  }

  // 格式化评分
  const formatRating = (rating) => {
    const numRating = parseFloat(rating);
    return isNaN(numRating) ? "0.0" : numRating.toFixed(1);
  };

  // 格式化评分数量
  const formatRatingCount = (count) => {
    if (!count) return "0";
    
    // 如果是数字，转换为字符串
    const countStr = count.toString();
    
    // 添加千位分隔符
    return new Intl.NumberFormat('en-US').format(count);
  };

  // 获取当前URL
  const currentUrl = window.location.href;

  const handleDownload = (url) => {
    if (url && url.startsWith('https://')) {
      window.location.href = url;
    }
  };

  // 添加一个console.log来检查数据
  console.log('App Data:', app?.result?.[0]?.result?.[0]?.item?.similar_apps);

  return (
    <div className="app-detail">
      {app && (
        <Helmet>
          <title>{app.name} - Download for Android and iOS</title>
        </Helmet>
      )}
      
      <div className="app-detail-header">
        <div className="app-basic-info">
          <img src={app.icon} alt={app.name} className="app-icon" />
          <div className="app-header-info">
            <h1>{app.name}</h1>
            <p className="developer">{app.developer}</p>
            <div className="rating-info">
              <span className="rating">⭐ {formatRating(app.rating)}</span>
              <span className="rating-count">({formatRatingCount(app.ratingCount)} ratings)</span>
            </div>
            {/* <p className="category">{app.category}</p> */}
          </div>
        </div>
      </div>

      <div className="app-detail-content">
        <div className="info-grid-section">
          <div className="info-grid">
            <div className="info-item">
              <h3>Version</h3>
              <p>{app?.version || 'Latest'}</p>
            </div>
            <div className="info-item">
              <h3>Size</h3>
              <p>{app?.size || 'Varies with device'}</p>
            </div>
            <div className="info-item">
              <h3>Category</h3>
              <p>{app?.category || 'Apps'}</p>
            </div>
            <div className="info-item">
              <h3>Last Updated</h3>
              <p>{app?.lastUpdated || 'Recently'}</p>
            </div>
            <div className="info-item">
              <h3>Safety</h3>
              <p>100% safe</p>
            </div>
            <div className="info-item">
              <h3>Developer</h3>
              <p>{app?.developer || 'Unknown'}</p>
            </div>
          </div>
        </div>

        <div className="download-buttons">
          <button onClick={() => handleDownload(window.location.href)} className="download-button apk-button">
            <img src="/images/android-icon.png" alt="Android" className="button-icon" />
            APK
          </button>
          <button  onClick={() => handleDownload(app?.url)} className="download-button play-button">
            <img src="/images/google-play-icon.png" alt="Google Play" className="button-icon" />
            Google Play
          </button>
          <button onClick={() => handleDownload(window.location.href)} className="download-button appstore-button">
            <img src="/images/apple-icon.png" alt="App Store" className="button-icon" />
            App Store
          </button>
        </div>

        <div className="description-section">
          <h2>Description</h2>
          <pre className="description-text">
            {formatDescription(app?.description)}
          </pre>
        </div>

        {app?.item?.images && app.item.images.length > 0 && (
          <section className="screenshots-section">
            <div className="screenshots-container">
              <button 
                className="scroll-button scroll-left"
                onClick={() => {
                  const container = document.querySelector('.screenshots-scroll');
                  container.scrollBy({ left: -200, behavior: 'smooth' });
                }}
              >
                ‹
              </button>
              
              <div className="screenshots-scroll">
                {app.item.images.map((imageUrl, index) => (
                  <div key={index} className="screenshot-item">
                    <img 
                      src={imageUrl} 
                      alt={`${app.name} screenshot ${index + 1}`}
                      loading="lazy"
                    />
                  </div>
                ))}
              </div>

              <button 
                className="scroll-button scroll-right"
                onClick={() => {
                  const container = document.querySelector('.screenshots-scroll');
                  container.scrollBy({ left: 200, behavior: 'smooth' });
                }}
              >
                ›
              </button>
            </div>
          </section>
        )}

        {app && app.similar_apps && app.similar_apps.length > 0 && (
          <section className="similar-apps">
            <h2>Similar Apps</h2>
            <div className="similar-apps-grid">
              {app.similar_apps.map((similarApp, index) => (
                <Link
                  to={`/app/${similarApp.app_id}`}
                  key={similarApp.app_id || index}
                  className="similar-app-card"
                >
                  <div className="similar-app-logo-container">
                    <img 
                      src={similarApp.icon} 
                      alt={`${similarApp.title} logo`}
                      className="similar-app-logo"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = '/default-app-icon.png';
                      }}
                    />
                  </div>
                  <div className="similar-app-content">
                    <h3>{similarApp.title}</h3>
                    <div className="similar-app-meta">
                      {similarApp.developer && (
                        <p className="developer">{similarApp.developer}</p>
                      )}
                      {similarApp.rating && (
                        <div className="rating-info">
                          <span className="rating">⭐ {formatRating(similarApp.rating)}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </section>
        )}
      </div>
    </div>
  );
}

export default AppDetail;


