import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import CategoryPage from './pages/CategoryPage';
import AppDetail from './pages/AppDetail';
import TopDownloads from './pages/TopDownloads';
import SearchResults from './pages/SearchResults';
import About from './pages/About';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import NotFound from './pages/NotFound';
import Footer from './components/Footer';
import Articles from './pages/Articles';
import ArticleDetail from './pages/ArticleDetail';
import EditorsChoice from './pages/EditorsChoice';
import './App.css';

function App() {
  return (
    <Router>
      <div className="app">
        <Navbar />
        <main className="main-content">
          <Routes>
            {/* 主页路由 */}
            <Route path="/" element={<Home />} />

            {/* SEO友好的URL格式 */}
            <Route path="/index.html" element={<Home />} />
            <Route path="/top-downloads/index.html" element={<TopDownloads />} />
            <Route path="/editors-choice/index.html" element={<EditorsChoice />} />
            <Route path="/articles/index.html" element={<Articles />} />
            <Route path="/articles/:id/index.html" element={<ArticleDetail />} />
            <Route path="/category/:category/index.html" element={<CategoryPage />} />

            {/* 保留原来的路由作为备用 */}
            <Route path="/top-downloads" element={<TopDownloads />} />
            <Route path="/editors-choice" element={<EditorsChoice />} />
            <Route path="/articles" element={<Articles />} />
            <Route path="/articles/:id" element={<ArticleDetail />} />
            <Route path="/category/:category" element={<CategoryPage />} />

            {/* 应用详情页路由 */}
            <Route path="/app/:id" element={<AppDetail />} />

            {/* 搜索结果页面路由 */}
            <Route path="/search" element={<SearchResults />} />

            {/* 其他页面路由 */}
            <Route path="/about" element={<About />} />
            <Route path="/privacy" element={<Privacy />} />
       <Route path="/terms" element={<Terms />} />

            {/* 旧路径重定向到新路径 */}
            <Route path="/social" element={<Navigate to="/category/social" replace />} />
            <Route path="/entertainment" element={<Navigate to="/category/entertainment" replace />} />
            <Route path="/finance" element={<Navigate to="/category/finance" replace />} />

            {/* 404 页面路由 */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;