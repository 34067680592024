import React from 'react';
import './SearchHero.css';

function SearchHero({ backgroundImage, appIcon, appName, appDescription }) {
  return (
    <div 
      className="search-hero" 
      style={{'--bg-image': `url(${backgroundImage})`}}
    >
      <div className="hero-content">
        <div className="app-showcase">
          <div className="app-main-info">
            <img src={appIcon} alt={appName} className="app-hero-icon" />
            <a href="/app/com.scopely.monopolygo" className="download-button">Download</a>
          </div>
          <div className="app-hero-info">
            <h1>{appName}</h1>
            <p>{appDescription}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchHero; 