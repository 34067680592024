import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
  const [searchTerm, setSearchTerm] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      navigate(`/search?q=${encodeURIComponent(searchTerm.trim())}`);
      setSearchTerm('');
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar">
      <div className="nav-container">
        <Link to="/" className="nav-logo">
          <img src="/logo_no_Bg.png" alt="AppFinder Logo" className="logo-image" />
          <span>AppFinder</span>
        </Link>
        
        <button className={`menu-toggle ${isMenuOpen ? 'active' : ''}`} onClick={toggleMenu}>
        <span className="hamburger"></span>
        </button>

        <div className={`nav-links ${isMenuOpen ? 'active' : ''}`}>
          <Link to="/" onClick={() => setIsMenuOpen(false)}>Home</Link>
          {/* <Link to="/categories" onClick={() => setIsMenuOpen(false)}>Categories</Link> */}
          <Link to="/top-downloads" onClick={() => setIsMenuOpen(false)}>Top Download</Link>
          {/* <Link to="/new" onClick={() => setIsMenuOpen(false)}>New</Link> */}
          <Link to="/articles" onClick={() => setIsMenuOpen(false)}>Articles</Link>
          <form onSubmit={handleSearch} className="nav-search">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search apps..."
              className="nav-search-input"
            />
          </form>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;