import ActionData from './categories/Action.json';
import AdventureData from './categories/Adventure.json';
import ArcadeData from './categories/Arcade.json';
import ArtDesignData from './categories/Art_Design.json';
import AutoVehiclesData from './categories/Auto_Vehicles.json';
import BeautyData from './categories/Beauty.json';
import BoardData from './categories/Board.json';
import BooksReferenceData from './categories/Books_Reference.json';
import BusinessData from './categories/Business.json';
import CardData from './categories/Card.json';
import CasualData from './categories/Casual.json';
import ComicsData from './categories/Comics.json';
import DatingData from './categories/Dating.json';
import EducationData from './categories/Education.json';
import EducationalData from './categories/Educational.json';
import EntertainmentData from './categories/Entertainment.json';
import EventsData from './categories/Events.json';
import FinanceData from './categories/Finance.json';
import FoodDrinkData from './categories/Food_Drink.json';
import HealthFitnessData from './categories/Health_Fitness.json';
import HouseHomeData from './categories/House_Home.json';
import LibrariesDemoData from './categories/Libraries_Demo.json';
import LifestyleData from './categories/Lifestyle.json';
import MapsNavigationData from './categories/Maps_Navigation.json';
import MedicalData from './categories/Medical.json';
import MusicAudioData from './categories/Music_Audio.json';
import NewsMagazinesData from './categories/News_Magazines.json';
import ParentingData from './categories/Parenting.json';
import PersonalizationData from './categories/Personalization.json';
import PhotographyData from './categories/Photography.json';
import ProductivityData from './categories/Productivity.json';
import PuzzleData from './categories/Puzzle.json';
import RacingData from './categories/Racing.json';
import RolePlayingData from './categories/Role_Playing.json';
import SimulationData from './categories/Simulation.json';
import SocialData from './categories/Social.json';
import SportsData from './categories/Sports.json';
import StrategyData from './categories/Strategy.json';
import ToolsData from './categories/Tools.json';
import TravelLocalData from './categories/Travel_Local.json';
import TriviaData from './categories/Trivia.json';
import WeatherData from './categories/Weather.json';
import WordData from './categories/Word.json';
import ShoppingData from './categories/Shopping.json';
import CommunicationData from './categories/Communication.json';
import VideoPlayersData from './categories/Video_Players_Editors.json';

export const categoryData = {
  'action': ActionData,
  'adventure': AdventureData,
  'arcade': ArcadeData,
  'art-design': ArtDesignData,
  'auto-vehicles': AutoVehiclesData,
  'beauty': BeautyData,
  'board': BoardData,
  'books-reference': BooksReferenceData,
  'business': BusinessData,
  'card': CardData,
  'casual': CasualData,
  'comics': ComicsData,
  'dating': DatingData,
  'education': EducationData,
  'educational': EducationalData,
  'entertainment': EntertainmentData,
  'events': EventsData,
  'finance': FinanceData,
  'food-drink': FoodDrinkData,
  'health-fitness': HealthFitnessData,
  'house-home': HouseHomeData,
  'libraries-demo': LibrariesDemoData,
  'lifestyle': LifestyleData,
  'maps': MapsNavigationData,
  'medical': MedicalData,
  'music-audio': MusicAudioData,
  'news-magazines': NewsMagazinesData,
  'parenting': ParentingData,
  'personalization': PersonalizationData,
  'photography': PhotographyData,
  'productivity': ProductivityData,
  'puzzle': PuzzleData,
  'racing': RacingData,
  'role-playing': RolePlayingData,
  'simulation': SimulationData,
  'social': SocialData,
  'sports': SportsData,
  'strategy': StrategyData,
  'shopping': ShoppingData,
  'tools': ToolsData,
  'travel': TravelLocalData,
  'trivia': TriviaData,
  'weather': WeatherData,
  'word': WordData,
  'communication': CommunicationData,
  'video-players': VideoPlayersData,
};

export const getCategoryData = (categoryId) => categoryData[categoryId];
export const categoryList = Object.keys(categoryData);
export default categoryData; 