import React from 'react';
import termsData from '../data/terms/term_of_uses.json';
import '../styles/pages/TermsPage.css';

function Terms() {
  const { terms_of_use } = termsData;

  return (
    <div className="terms-page">
      <div className="terms-container">
        <h1 className="terms-title">Terms of Service</h1>

        <div className="terms-content">
          <section className="terms-section">
            <h2>Introduction</h2>
            <div className="terms-text">
              {terms_of_use.introduction.description}
            </div>
          </section>

          <section className="terms-section">
            <h2>Agreement</h2>
            <div className="terms-text">
              {terms_of_use.agreement.description}
            </div>
          </section>

          <section className="terms-section">
            <h2>Use Restrictions</h2>
            <div className="terms-subsection">
              <h3>Copyright</h3>
              <div className="terms-text">
                {terms_of_use.use_restrictions.copyright}
              </div>
            </div>
            <div className="terms-subsection">
              <h3>Personal Use</h3>
              <div className="terms-text">
                {terms_of_use.use_restrictions.personal_use}
              </div>
            </div>
            <div className="terms-subsection">
              <h3>Child Privacy</h3>
              <div className="terms-text">
                {terms_of_use.use_restrictions.child_privacy}
              </div>
            </div>
          </section>

          <section className="terms-section">
            <h2>Disclaimer</h2>
            <div className="terms-text">
              {terms_of_use.disclaimer.description}
            </div>
          </section>

          <section className="terms-section">
            <h2>Limitation of Liability</h2>
            <div className="terms-text">
              {terms_of_use.limitation_of_liability.description}
            </div>
          </section>
        </div>

        <div className="terms-footer">
          <p>Last Updated: {terms_of_use.last_updated}</p>
        </div>
      </div>
    </div>
  );
}

export default Terms; 