import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './AppCard.css';

function AppCard({ app }) {
  const defaultIcon = 'https://via.placeholder.com/100?text=App';
  const [retryCount, setRetryCount] = useState(0);
  const maxRetries = 3;

  const handleImageError = (e) => {
    if (retryCount < maxRetries) {
      setRetryCount(prev => prev + 1);
      e.target.src = app.icon;
    } else {
      // 达到最大重试次数，使用默认图标
      e.target.onerror = null;
      e.target.src = defaultIcon;
    }
  };

  return (
    <Link to={`/app/${app.id}`} href={`/app/${app.id}`} className="app-card">
      <img 
        src={app.icon || defaultIcon} 
        alt={app.name} 
        className="app-icon"
        onError={handleImageError}
      />
      <div className="app-info">
        <h3 className="app-name">{app.name}</h3>
        {app.description && (
          <p className="app-description">{app.description}</p>
        )}
        <div className="app-meta">
          <span className="app-rating">⭐ {app.rating}</span>
          <span className="app-downloads">{app.downloads}</span>
        </div>
      </div>
    </Link>
  );
}

export default AppCard; 