import { categoryData } from '../data/index';

// 清理文本的辅助函数
const cleanText = (text) => {
  return text ? text.replace(/[\n\r\t]/g, ' ').trim() : '';
};

// 生成随机评分的辅助函数（如果需要的话）
const generateRandomRating = () => {
  return (Math.random() * 3 + 2).toFixed(1);
};

// 通用的数据处理函数
const processAppData = (item) => ({
  id: item.app_id,
  name: cleanText(item.item.title),
  icon: item.item.icon,
  developer: cleanText(item.item.developer),
  description: cleanText(item.item.description),
  category: item.item.main_category,
  url: item.item.url,
  rating: item.item.rating?.value || generateRandomRating(),
  ratingCount: item.item.reviews_count,
  version: item.item.version || "Latest",
  size: item.item.size || "Varies with device",
  lastUpdated: item.item.last_update_date
    ? new Date(item.item.last_update_date).toLocaleDateString()
    : "Recently updated",
  similar_apps: item.item.similar_apps || [],
  item: {
    images: item.item.images || []
  }
});

// 处理所有类别的数据
const processedCategoryData = {};
Object.entries(categoryData).forEach(([category, data]) => {
  if (data && data.result && data.result[0] && data.result[0].items) {
    processedCategoryData[category] = data.result[0].items.map(processAppData);
  } else {
    console.warn(`Warning: Invalid data structure for category ${category}`);
    processedCategoryData[category] = [];
  }
});

// 合并所有应用数据
const allApps = Object.values(processedCategoryData).flat();

// 获取完整的相似应用信息
const getEnrichedSimilarApps = (similarApps) => {
  return similarApps
    .map(similarApp => {
      // 在所有应用中查找完整信息
      const fullAppInfo = allApps.find(app => app.id === similarApp.app_id);
      
      if (fullAppInfo) {
        // 如果找到完整信息，返回扩展的对象
        return {
          app_id: similarApp.app_id,
          title: similarApp.title,
          url: similarApp.url,
          icon: fullAppInfo.icon,
          rating: fullAppInfo.rating,
          developer: fullAppInfo.developer
        };
      }
      
      // 如果没找到完整信息，返回 null
      return null;
    })
    .filter(app => app !== null); // 过滤掉所有 null 值
};

const appService = {
  // 获取所有应用
  getAllApps: () => allApps,

  // 获取特定类别的应用
  getAppsByCategory: (categoryId) => {
    const normalizedCategoryId = categoryId.toLowerCase().replace(/\s+/g, '-');
    return processedCategoryData[normalizedCategoryId] || [];
  },

  // 获取单个应用详情
  getAppById: (id) => {
    const app = allApps.find(app => app.id === id);
    if (app && app.similar_apps) {
      // 使用 getEnrichedSimilarApps 扩充 similar_apps 信息，只保留有完整信息的应用
      app.similar_apps = getEnrichedSimilarApps(app.similar_apps);
    }
    return app;
  },

  // 获取热门应用
  getTopApps: (limit = 10) => {
    return [...allApps]
      .sort((a, b) => b.ratingCount - a.ratingCount)
      .slice(0, limit);
  },

  // 搜索应用
  searchApps: (query) => {
    const searchTerm = query.toLowerCase();
    return allApps.filter(app => 
      app.name.toLowerCase().includes(searchTerm) ||
      app.developer.toLowerCase().includes(searchTerm)
    );
  },

  // 获取相似应用
  getSimilarApps: (appId, limit = 5) => {
    const app = allApps.find(a => a.id === appId);
    if (!app?.similar_apps) return [];
    
    // 使用 getEnrichedSimilarApps 获取丰富的相似应用数据
    const enrichedApps = getEnrichedSimilarApps(app.similar_apps);
    return enrichedApps.slice(0, limit);
  },

  // 获取特定类别的热门应用
  getTopAppsByCategory: (categoryId, limit = 10) => {
    const categoryApps = processedCategoryData[categoryId] || [];
    return [...categoryApps]
      .sort((a, b) => b.ratingCount - a.ratingCount)
      .slice(0, limit);
  },

  // 获取所有类别列表
  getAllCategories: () => Object.keys(processedCategoryData)
};

export default appService;