import React from 'react';
import { articles } from '../data/articles/articles';
import './Articles.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function Articles() {
  return (
    <div className="articles-page">
      <Helmet>
      <title>AppFinder - Download Apps for Android and iOS</title>
      </Helmet>
      <div className="articles-container">
        <div className="page-header">
          <h1>Articles</h1>
          <p>Latest AI Technology News and Guides</p>
        </div>
        
        <div className="articles-grid">
          {articles.map(article => (
            <Link 
              to={`/articles/${article.id}/index.html`} 
              key={article.id} 
              className="article-card"
            >
              <div className="article-image">
                <img src={article.coverImage} alt={article.title} />
              </div>
              <div className="article-content">
                <h2>{article.title}</h2>
                <p className="article-summary">{article.summary}</p>
                <div className="article-meta">
                  <span>{article.date}</span>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Articles; 