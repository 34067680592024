import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { categoryData } from '../data';
import AppCard from '../components/AppCard';
import './CategoryPage.css';

function CategoryPage() {
  const { category } = useParams();

  // 获取类别数据
  const categoryApps = categoryData[category]?.result?.[0]?.items || [];

  // 添加一个格式化描述的函数
  const formatDescription = (description) => {
    if (!description) return '';
    // 将连续的多个换行符替换为两个换行符
    const cleanText = description.replace(/\n+/g, '\n\n');
    // 将文本按句号分割，每个句号后添加换行符
    // 但要避免处理小数点和已有换行符的情况
    return cleanText.replace(/\. (?=[A-Z])/g, '.\n\n');
  };

  // 在处理数据时应用格式化
  const processedApps = categoryApps.map(item => ({
    id: item.app_id,
    name: item.item.title,
    icon: item.item.icon,
    developer: item.item.developer,
    description: formatDescription(item.item.description),  // 使用格式化函数
    category: item.item.main_category,
    url: item.item.url,
    rating: item.item.rating?.value || "N/A",
    ratingCount: item.item.reviews_count,
    version: item.item.version || "Latest",
    size: item.item.size || "Varies with device",
    lastUpdated: item.item.last_update_date
      ? new Date(item.item.last_update_date).toLocaleDateString()
      : "Recently updated"
  }));

  // 获取类别显示名称（将连字符替换为空格，首字母大写）
  const getCategoryDisplayName = () => {
    return category
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  // 添加调试日志
  console.log('Category:', category);
  console.log('Category Data:', categoryData[category]);
  console.log('Available Categories:', Object.keys(categoryData));

  if (!categoryData[category]) {
    return <div className="category-page">类别不存在</div>;
  }

  return (
    <div className="category-page">
      <Helmet>
        <title>{`${getCategoryDisplayName()} Apps - AppFinder`}</title>
        <meta name="description" content={`Download the best ${getCategoryDisplayName()} apps for Android`} />
      </Helmet>
      <h1>{getCategoryDisplayName()} Apps</h1>
      <div className="apps-grid">
        {processedApps.map(app => (
          <AppCard key={app.id} app={app} />
        ))}
      </div>
    </div>
  );
}

export default CategoryPage;