import React from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { articles } from '../data/articles/articles';
import './ArticleDetail.css';
import { Helmet } from 'react-helmet';

function ArticleDetail() {
  const { id } = useParams();
  const article = articles.find(article => article.id === id);

  if (!article) {
    return <div className="article-not-found">Article not found</div>;
  }

  return (
    <div className="article-detail-page">
      {article && (
        <Helmet>
          <title>{article.title} - AppFinder</title>
        </Helmet>
      )}
      <div className="article-detail-container">
        <article className="article-content">
          <h1 className="article-title">{article.title}</h1>
          <div className="article-meta">
            <time>{article.date}</time>
          </div>
          <div className="article-body">
            <ReactMarkdown 
              components={{
                h1: 'h2',  // 将文章内容中的 h1 转换为 h2
                p: ({children}) => <p className="article-paragraph">{children}</p>,
                strong: ({children}) => <strong className="article-bold">{children}</strong>,
              }}
            >
              {article.content}
            </ReactMarkdown>
          </div>
        </article>
      </div>
    </div>
  );
}

export default ArticleDetail; 