import React from 'react';
import aboutData from '../data/terms/about_us.json';
import '../styles/pages/AboutPage.css';

function About() {
  const { why_proappfinder, important_notice, contact_us } = aboutData.about_us;

  return (
    <div className="about-page">
      <div className="about-container">
        <h1>About Us</h1>
        
        <section className="about-section">
          <h2>Why ProAppFinder</h2>
          <p>{why_proappfinder.description}</p>
        </section>

        <section className="about-section">
          <h2>Important Notice</h2>
          <p>{important_notice.description}</p>
        </section>

        <section className="about-section">
          <h2>Contact Us</h2>
          <p>{contact_us.description}</p>
        </section>
      </div>
    </div>
  );
}

export default About; 
