import React from 'react';
import { useSearchParams } from 'react-router-dom';
// import { appService } from '../services/appService';
import AppCard from '../components/AppCard';
import './SearchResults.css';
import appService from '../services/appService';  // 使用默认导入

function SearchResults() {
  const [searchParams] = useSearchParams();
  const query = searchParams.get('q') || '';
  
  const searchResults = appService.searchApps(query);

  return (
    <div className="search-results">
      <h2>Search Results for "{query}"</h2>
      <div className="results-grid">
        {searchResults.map(app => (
          <AppCard key={app.id} app={app} />
        ))}
      </div>
    </div>
  );
}

export default SearchResults; 